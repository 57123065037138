import LocaleService from '@webolucio/core/services/locale';
import userspaceMessages, { translations as userspaceTranslations } from '@webolucio/userspace/i18n';
import { PublicAppMessages } from 'lib/i18n/types';

const webolucioMessages = userspaceMessages as PublicAppMessages;
export const buttonMessages = webolucioMessages.button;
export const commonMessages = webolucioMessages.common;
export const errorMessages = webolucioMessages.error;
export const validationMessages = webolucioMessages.validation;

export const translations = LocaleService.getService().loadTranslations(
	import.meta.webpackContext ? [import.meta.webpackContext('./translations')] : [],
	userspaceTranslations,
);

export default webolucioMessages;
