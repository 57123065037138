// Webolucio imports
import { WebolucioRouteObject } from '@webolucio/web';
import { App, NotFoundPage, UnauthorizedPage } from '@webolucio/userspace';
// App imports
import config from 'config';
import DynamicPage from 'pages/DynamicPage';

const routes: WebolucioRouteObject[] = [
	{
		Component: App,
		children: [
			{ Component: UnauthorizedPage, path: config.modules.auth.frontendPaths.unauthorized || '/' },
			{
				path: config.defaultClientPaths.notFound,
				Component: NotFoundPage,
			},
			{ path: '*', Component: DynamicPage },
		],
	},
];
export default routes;

export const pageNotFoundPath = '/page-not-found';
