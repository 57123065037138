import '@webolucio/core/security/csp';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
// Webolucio imports
import configureAppStore from '@webolucio/core/services/store';
import { LanguageProvider } from '@webolucio/core/i18n/context';
import { DialogProvider } from '@webolucio/core/modules/dialogs/DialogContext';
import { IconDefinitionProvider } from '@webolucio/icons';
import { Dialog, ErrorBoundary, CookieLocaleAdapter, LocalForageAdapter, ReactRouterAdapter, WebolucioRouterProvider } from '@webolucio/web';
import LocaleService from '@webolucio/core/services/locale';
import LocalStorageService from '@webolucio/core/services/localStorage';
import NavigationService from '@webolucio/core/services/navigation';
// External imports
import routes from 'app/routes';
import ErrorComponent from 'components/containers/ErrorBoundary';
import { translations } from 'i18n';
import './styles/main.scss';
// Local imports
import * as serviceWorker from './serviceWorker';

LocaleService.getService().setAdapter(CookieLocaleAdapter.getInstance());
LocalStorageService.getService().setAdapter(LocalForageAdapter.getInstance());
NavigationService.getService().setAdapter(ReactRouterAdapter.getInstance());

const store = configureAppStore();
export const MOUNT_NODE = document.getElementById('root') as HTMLElement;

function AppContainer() {
	return (
		<StrictMode>
			<ErrorBoundary errorComponent={ErrorComponent}>
				<Provider store={store}>
					<LanguageProvider translations={translations}>
						<IconDefinitionProvider>
							<SnackbarProvider>
								<DialogProvider dialogComponent={Dialog}>
									<WebolucioRouterProvider routes={routes} />
								</DialogProvider>
							</SnackbarProvider>
						</IconDefinitionProvider>
					</LanguageProvider>
				</Provider>
			</ErrorBoundary>
		</StrictMode>
	);
}

ReactDOM.render(<AppContainer />, MOUNT_NODE);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
