import { defineMessages } from 'react-intl';

export const scope = 'srgagency.public.containers.ErrorBoundary';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Something went wrong.',
	},
	description: {
		id: `${scope}.description`,
		defaultMessage: 'We are already working on it, please try again later.',
	},
});
