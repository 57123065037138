import { ComponentType, Fragment } from 'react';
// Webolucio imports
import { ContentComponent, ContentTypes } from '@webolucio/web/types/dynamicContent';
// External imports
import Breadcrumb from 'content/components/contentual/Breadcrumb/loadable';
import Form from '@webolucio/userspace/content/components/contentual/Form/loadable';
import Image from '@webolucio/userspace/content/components/contentual/Image/loadable';
import LanguageSelector from 'content/components/contentual/LanguageSelector/loadable';
import Map from './components/contentual/Map/loadable';
import Menu from './components/contentual/Menu/loadable';
//import Gallery from '@webolucio/userspace/content/components/contentual/Gallery/loadable';
import PostFilter from '@webolucio/userspace/content/components/contentual/PostFilter/loadable';
import Placeholder from '@webolucio/userspace/content/components/contentual/Placeholder/loadable';
import SearchForm from '@webolucio/userspace/content/components/contentual/SearchForm/loadable';
import SearchResult from '@webolucio/userspace/content/components/contentual/SearchResult/loadable';
import Post from './components/contentual/Post/loadable';
import PostExplaining from './components/contentual/PostExplaining/loadable';
import Section from '@webolucio/userspace/content/components/structural/Section/loadable';
import Text from './components/contentual/Text/loadable';
import Title from '@webolucio/userspace/content/components/contentual/Title/loadable';

// TS and compose do not like each other https://stackoverflow.com/questions/54355096/how-to-use-hoc-with-redux-compose-and-typescript
export const contentTypes: ContentComponent = {
	// TODO Create a type for this that does not need to be casted every time
	[ContentTypes.Breadcrumb]: Breadcrumb,
	[ContentTypes.Form]: Form,
	[ContentTypes.Gallery]: Fragment,
	[ContentTypes.Image]: Image,
	[ContentTypes.Map]: Map,
	[ContentTypes.Menu]: Menu,
	[ContentTypes.LanguageSelector]: LanguageSelector,
	[ContentTypes.Placeholder]: Placeholder,
	[ContentTypes.Post]: Post,
	[ContentTypes.PostExplaining]: PostExplaining,
	[ContentTypes.PostFilter]: PostFilter,
	[ContentTypes.SearchForm]: SearchForm,
	[ContentTypes.SearchResult]: SearchResult,
	[ContentTypes.Section]: Section as unknown as ComponentType,
	[ContentTypes.Text]: Text,
	[ContentTypes.Title]: Title,
};
